import { Login } from "../../features/login";
import { Container } from "../../shared/ui";

export const PageLogin = () => {
  return (
    <Container
      sx={{
        p: 2,
      }}
    >
      <Login />
    </Container>
  );
};
