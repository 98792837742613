import { queryClient } from "../../app/providers";
import { getTradeOutlets } from "../../shared/api";

export const newOrderPageLoader = async () => {
  const tradeOutlets = await queryClient.fetchQuery({
    queryKey: ["outlets"],
    queryFn: () => {
      return getTradeOutlets();
    },
    staleTime: 10000,
  });

  const defaultTradeOutletId =
    localStorage.getItem("currentTradeOutletId") ?? undefined;

  return { defaultTradeOutletId, tradeOutlets };
};
