import { useCallback, useMemo } from "react";

import { getSession } from "../../../../entities/sessions";
import { decode } from "../../../../shared/lib/jwt";

export const useRoles = () => {
  const session = getSession();
  const decodedToken = decode<{ roles?: string[] }>(session?.accessToken || "");

  const roles = useMemo(() => decodedToken?.roles || [], [decodedToken]);

  const handleCheckMemberOf = useCallback(
    (role: string) => {
      return roles.includes(role);
    },
    [roles],
  );

  return {
    roles,
    isMemberOf: handleCheckMemberOf,
  };
};
