import { LoaderFunctionArgs } from "react-router-dom";

import { queryClient } from "../../app/providers";
import { getOrders, getTradeOutlets } from "../../shared/api";

export const ordersPageLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const orderNumber = url.searchParams.get("orderNumber") || undefined;

  const outlets = await queryClient.fetchQuery({
    queryKey: ["outlets"],
    queryFn: () => {
      return getTradeOutlets();
    },
    staleTime: 10000,
  });

  // @TODO Move to storage key/hook
  const currentTradeOutletId = localStorage.getItem("currentTradeOutletId");

  const isCurrentTradeOutletExists =
    currentTradeOutletId && outlets.some((o) => o.id === currentTradeOutletId);
  let defaultTradeOutletId = outlets[0].id;

  if (isCurrentTradeOutletExists) {
    defaultTradeOutletId = currentTradeOutletId;
  } else {
    localStorage.setItem("currentTradeOutletId", String(outlets[0].id));
  }

  const orders = await queryClient.fetchQuery({
    queryKey: ["orders", defaultTradeOutletId, orderNumber],
    queryFn: () => {
      return getOrders({
        tradeOutletId: defaultTradeOutletId,
        orderBy: "-created",
        statusIn: [
          "ACTIVE",
          "CONFIRMED",
          "PAYMENT_FAILED",
          "PENDING",
          "PROCESSING",
          "REFUNDED",
          "REFUND_FAILED",
          "REFUND_IN_PROGRESS",
          "REPAID",
        ],
        orderNumberSearch:
          orderNumber && orderNumber.length > 2 ? orderNumber : undefined,
      });
    },
    staleTime: 10000,
  });

  return {
    orders,
    orderNumber,
  };
};
