import { useMutation } from "@tanstack/react-query";

import { setSession } from "../../../../entities/sessions";
import { confirmCode } from "../../../../shared/api";

export const useConfirmCode = () => {
  return useMutation({
    mutationFn: confirmCode,
    onSuccess: (data) => {
      const { accessToken, refreshToken } = data;

      if (!accessToken || !refreshToken) {
        return;
      }

      setSession({ accessToken, refreshToken });
    },
  });
};
