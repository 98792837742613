import { InternalAxiosRequestConfig } from "axios";

import { getSession } from "../../../entities/sessions";
import { AUTH_TOKEN_HEADER_KEY } from "./constants";

export const addAuthTokenHeader = (config: InternalAxiosRequestConfig) => {
  const { accessToken } = getSession();

  if (accessToken) {
    config.headers.set(AUTH_TOKEN_HEADER_KEY, `Bearer ${accessToken}`);
  }

  return config;
};
