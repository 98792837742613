import { ActionEvent } from "./types";

export const ACTION_EVENTS = {
  Login: {
    name: "login",
  },
  Logout: {
    name: "logout",
  },
  // Main (Orders)
  ClickCreateOrder: {
    name: "click_create_order",
  },
  ClickOrder: {
    name: "click_order",
  },
  ClickMenu: {
    name: "click_menu",
  },
  ClickTradeOutletSelect: {
    name: "click_trade_outlet_select",
  },
  FocusOrderSearch: {
    name: "focus_order_search",
  },
  // Trade outlet select screen
  SelectTradeOutlet: {
    name: "select_trade_outlet",
  },
  ClickChangeTradeOutlet: {
    name: "click_change_trade_outlet",
  },
  ClickCancelTradeOutletSelect: {
    name: "click_cancel_trade_outlet_select",
  },
  // Order
  ClickCheckPaymentStatus: {
    name: "click_check_payment_status",
  },
  ClickCloseOrder: {
    name: "click_close_order",
  },
  ClickRefundOrder: {
    name: "click_refund_order",
    properties: {
      order_id: "string",
    },
  },
  ClickRefundOrderConfirm: {
    name: "click_refund_order_confirm",
    properties: {
      order_id: "string",
    },
  },

  ClickRefundOrderCancel: {
    name: "click_refund_order_cancel",
    properties: {
      order_id: "string",
    },
  },
  ClickSendPaymentLink: {
    name: "click_send_payment_link",
    properties: {
      order_id: "string",
    },
  },
  ClickCopyPaymentLink: {
    name: "click_copy_payment_link",
    properties: {
      order_id: "string",
    },
  },
  ClickSendPaymentLinkByEmail: {
    name: "click_send_payment_link_by_email",
    properties: {
      order_id: "string",
    },
  },
} as const satisfies Record<string, ActionEvent>;
