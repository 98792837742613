import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";

import { useLogout } from "../../features/logout";
import { Paths } from "../../shared/config";
import { analytics } from "../../shared/lib/analytics";
import { getConfig } from "../../shared/lib/config";
import {
  ActionButton,
  Box,
  Container,
  FormatPhone,
  IconCheck,
  IconChevronRight,
  PageTitle,
  Stack,
  Typography,
} from "../../shared/ui";
import { profilePageLoader } from "./loader";

type RowProps = {
  name: string;
  value: React.ReactNode;
};

const Row = ({ name, value }: RowProps) => {
  return (
    <ActionButton
      description={value as string}
      onClick={() => {}}
      text={name}
      endIcon={<IconCheck color="primary" />}
    />
  );
};

export const PageProfile = () => {
  const { employee } = useLoaderData() as Awaited<
    ReturnType<typeof profilePageLoader>
  >;

  const { t } = useTranslation();

  const navigate = useNavigate();
  const logout = useLogout();

  const config = getConfig();
  const regexp = new RegExp(`^\\${config.country.phones.prefix.international}`);

  const handleLogoutActionButtonClick = () => {
    analytics.logAction("Logout");

    logout.mutate(undefined, {
      onSettled: () => {
        navigate(Paths.Welcome);
      },
    });
  };

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <PageTitle title="Menu" />
      <Box mb={3}>
        <Typography mb={2} variant="bigTextMedium">
          {t("profile.user_info.title")}
        </Typography>
        <Stack spacing={1}>
          <Row
            name={t("profile.user_info.fields.name")}
            value={employee.name}
          />
          <Row
            name={t("profile.user_info.fields.phone")}
            value={
              // TODO: implement this logic in shared layer
              <FormatPhone
                countryCode={config.country.code}
                value={employee.phone.replace(regexp, "")}
              />
            }
          />
        </Stack>
      </Box>
      <Box>
        <Typography mb={2} variant="bigTextMedium">
          {t("profile.account.title")}
        </Typography>
        <ActionButton
          endIcon={<IconChevronRight />}
          onClick={handleLogoutActionButtonClick}
          text={t("profile.logout_button_text")}
        />
      </Box>
    </Container>
  );
};
