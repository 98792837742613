import React from "react";

import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  SessionContext,
} from "../../../entities/sessions";
import { setAnalyticsSession } from "../../../shared/lib/analytics";

export type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const SessionProvider = ({ children }: Props) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);

  setAnalyticsSession(accessToken);

  return (
    <SessionContext.Provider value={{ accessToken, refreshToken }}>
      {children}
    </SessionContext.Provider>
  );
};
