import { enqueueSnackbar } from "@mychili/ui-web";

import { axios } from "../../../shared/lib/axios";

export const handleLoginNetworkError = (error: Error) => {
  if (!(error instanceof axios.AxiosError)) return;

  const clientError = error?.response?.data?.error?.message;
  const phoneError = error?.response?.data?.error?.errors?.phone_number?.[0];

  if (clientError) {
    enqueueSnackbar(clientError, { variant: "error" });
  }

  if (phoneError) {
    enqueueSnackbar(phoneError, { variant: "error" });
  }
};
