import "./styles";

import * as Amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";

import { AMPLITUDE_API_KEY } from "../shared/config";
import { Sentry } from "../shared/lib/sentry";
import {
  QueryClientProvider,
  RouterProvider,
  SessionProvider,
  SnackbarProvider,
  ThemeProvider,
} from "./providers";

Sentry.init();

Amplitude.init(AMPLITUDE_API_KEY);

export const App = () => {
  useEffect(() => {
    // Cache issue fix (Temporary)
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((reg) => reg.unregister());
      });
    }
  }, []);

  return (
    <QueryClientProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <SessionProvider>
            <RouterProvider />
          </SessionProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
