import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../../app/providers";
import { GetTradeOutletsResponseData } from "../../../shared/api";
import { currencyUnit } from "../../../shared/config";
import { getNetworkErrorMessage } from "../../../shared/lib/axios";
import { enqueueSnackbar } from "../../../shared/lib/common";
import {
  ActionButton,
  Box,
  Button,
  IconChevronRight,
  IconLocation,
  Input,
  InputCurrency,
  Stack,
} from "../../../shared/ui";
// TODO: to entities
import { useCreateOrder } from "../model";

// TODO: object for all paths
const orderPagePath = (orderId: string) => {
  return `/orders/${orderId}`;
};

export type CreateOrderFormProps = {
  defaultTradeOutletId?: string;
  onTradeOutletButtonClick: () => void;
  tradeOutlets: GetTradeOutletsResponseData;
};

export type OrderItem = {
  name: string;
};

export const enum CreateOrderFormInputName {
  Amount = "amount",
  OrderItems = "orderItems",
  ReferenceNumber = "referenceNumber",
  TradeOutletId = "tradeOutletId",
}

export type CreateOrderFormInputs = {
  [CreateOrderFormInputName.Amount]: string;
  [CreateOrderFormInputName.OrderItems]: OrderItem[];
  [CreateOrderFormInputName.ReferenceNumber]: string;
  [CreateOrderFormInputName.TradeOutletId]: string;
};

export const CreateOrderForm = ({
  defaultTradeOutletId,
  tradeOutlets,
  onTradeOutletButtonClick,
}: CreateOrderFormProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const createOrder = useCreateOrder();

  const outletData = tradeOutlets.find((tradeOutlet) => {
    return tradeOutlet.id === defaultTradeOutletId;
  });

  const defaultValues = {
    [CreateOrderFormInputName.Amount]: "",
    [CreateOrderFormInputName.OrderItems]: [],
    [CreateOrderFormInputName.ReferenceNumber]: "",
    [CreateOrderFormInputName.TradeOutletId]: defaultTradeOutletId,
  };

  const { control, formState, handleSubmit } = useForm<CreateOrderFormInputs>({
    defaultValues,
  });

  const handleBackButtonClick = () => {
    return navigate(-1);
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit((data) => {
        createOrder.mutate(
          {
            amount: Number(data.amount.replace(/[^\d.]/g, "")),
            tradeOutletId: data.tradeOutletId,
            orderItems: data.orderItems,
            referenceNumber: data.referenceNumber || undefined,
          },
          {
            onError: (error: Error) => {
              const message = getNetworkErrorMessage(error);

              enqueueSnackbar({
                message,
                variant: "error",
              });
            },
            onSuccess: ({ id }) => {
              if (!id) {
                return; // TODO: Sentry (what to do)
              }

              queryClient.invalidateQueries({
                queryKey: ["orders"],
              });
              navigate(orderPagePath(id), { replace: true });
            },
          },
        );
      })}
      spacing={3}
    >
      <ActionButton
        description={outletData?.locationGeo || undefined}
        endIcon={<IconChevronRight />}
        onClick={onTradeOutletButtonClick}
        startIcon={<IconLocation color="primary" />}
        text={outletData?.name}
      />
      <Controller
        control={control}
        name={CreateOrderFormInputName.Amount}
        render={({ field, fieldState }) => {
          return (
            <InputCurrency
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              placeholder={t("orders.create.amount_input.placeholder")}
              currency={currencyUnit}
              allowDecimal
              {...field}
            />
          );
        }}
        rules={{
          required: t("common.validation.required"),
        }}
      />
      <Controller
        control={control}
        name={CreateOrderFormInputName.ReferenceNumber}
        render={({ field, fieldState }) => {
          return (
            <Input
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              placeholder={t("orders.create.number_input.placeholder")}
              {...field}
            />
          );
        }}
      />
      <Box
        mt={{
          xs: "112px",
          md: 2,
        }}
      >
        <Box
          bottom={16}
          left={16}
          position={{
            xs: "fixed",
            md: "static",
          }}
          right={16}
        >
          <Button
            disabled={!formState.isDirty}
            fullWidth={true}
            sx={{
              mb: 1,
            }}
            type="submit"
          >
            {t("common.continue")}
          </Button>
          <Button
            fullWidth={true}
            onClick={handleBackButtonClick}
            variant="secondary"
          >
            {t("common.back")}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};
