import { enqueueSnackbar } from "@mychili/ui-web";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  getCodeErrorMessage,
  useConfirmCode,
  useGetCode,
} from "../../../../features/login";
import { Paths } from "../../../../shared/config";
import { analytics } from "../../../../shared/lib/analytics";
import { getNetworkErrorMessage } from "../../../../shared/lib/axios";
import { getConfig } from "../../../../shared/lib/config";
import { parsePhoneNumber } from "../../../../shared/lib/phones";
import { getCountdown, useTimer } from "../../../../shared/lib/timer";
import {
  Box,
  Button,
  IconCall,
  InputCode,
  InputCodeProps,
  InputPhone,
  Link,
  PageTitle,
  Show,
} from "../../../../shared/ui";
import { handleLoginNetworkError } from "../../lib";

const Code = ({
  onConfirm,
  ...props
}: Omit<InputCodeProps, "onChange" | "value"> & {
  onConfirm: (code: string) => void;
}) => {
  const [code, setCode] = useState("");

  useEffect(() => {
    if (code.length === 4) {
      onConfirm(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleChange = (value: string) => {
    setCode(value);
  };

  return <InputCode onChange={handleChange} value={code} {...props} />;
};

const Resend = ({
  countdown = 0,
  onResend,
}: {
  countdown?: number;
  onResend: () => void;
}) => {
  const { t } = useTranslation();

  const timer = useTimer(countdown);

  const buttonText =
    timer.state === "started"
      ? t("login.confirm.resend_button_text.countdown", {
          seconds: getCountdown(timer.seconds),
        })
      : t("login.confirm.resend_button_text");

  return (
    <Button
      color="primary"
      disabled={timer.state === "started"}
      onClick={onResend}
      size="medium"
      variant="link"
    >
      {buttonText}
    </Button>
  );
};

export type EnterPhoneNumberViewProps = {
  error: unknown;
  onSubmit: (values: { phoneNumber: string }) => void;
};

const EnterPhoneNumber = ({ error, onSubmit }: EnterPhoneNumberViewProps) => {
  const { t } = useTranslation();

  const getCode = useGetCode();

  const [phoneNumber, setPhoneNumber] = useState("");

  const config = getConfig();

  const handleInputPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.currentTarget.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({ phoneNumber });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <InputPhone
        autoFocus={true}
        countryCode={config.country.code}
        error={Boolean(error)}
        helperText={getCodeErrorMessage(error)}
        inputProps={{
          type: "tel",
        }}
        onChange={handleInputPhoneChange}
        placeholder={t("login.phone_input.placeholder")}
        InputProps={{
          startAdornment: <IconCall />,
        }}
        sx={{
          mb: 2,
        }}
        color="white"
      />
      <Button
        color="primary"
        fullWidth={true}
        loading={getCode.isPending}
        type="submit"
        disabled={!phoneNumber}
      >
        {t("login.submit_button_text")}
      </Button>
    </Box>
  );
};

export type ConfirmPhoneNumberView = {
  authId: string;
  countdown?: number;
  isInvalid?: boolean;
  onBlocked: () => void;
  onResend: () => void;
};

const ConfirmPhoneNumber = ({
  authId,
  countdown = 0,
  onBlocked,
  onResend,
}: ConfirmPhoneNumberView) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const confirmCode = useConfirmCode();

  const handleCodeConfirm = (code: string) => {
    confirmCode.mutate(
      {
        appVersion: "web", // TODO: to config
        authId,
        code,
      },
      {
        onError: (error) => {
          // @ts-ignore
          const isBlocked = error?.response?.data?.error?.meta?.is_blocked;

          if (isBlocked) {
            onBlocked();
          }
        },
        onSuccess: () => {
          analytics.logAction("Login");
          navigate(Paths.Main);
        },
      },
    );
  };

  return (
    <Box>
      <Code
        error={confirmCode.isError}
        helperText={
          confirmCode.isError ? t("login.confirm.wrong_code_error") : null
        }
        onConfirm={handleCodeConfirm}
      />
      <Box mb={2} textAlign="center">
        <Resend countdown={countdown} onResend={onResend} />
      </Box>
    </Box>
  );
};

export const Login = () => {
  const { t } = useTranslation();

  const getCode = useGetCode();

  const countdown = getCode.data?.countdown;

  const [authId, setAuthId] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [isBlocked, setIsBlocked] = useState(false);

  const config = getConfig();

  const subtitle = isBlocked ? (
    <span>
      {t("login.confirm.description_blocked")}{" "}
      <Link
        href={`mailto:${config.project.contacts.supportEmail}`}
        underline="none"
      >
        {config.project.contacts.supportEmail}
      </Link>
    </span>
  ) : (
    t("login.confirm.description")
  );

  const title = isBlocked
    ? t("login.confirm.title_blocked")
    : t("login.confirm.title");

  const handlePhoneNumberSubmit = (values: { phoneNumber: string }) => {
    getCode.mutate(
      { phoneNumber: parsePhoneNumber(values.phoneNumber) },
      {
        onSuccess: (data, variables) => {
          setAuthId(data.authId);
          setPhoneNumber(variables.phoneNumber);
        },
        onError: handleLoginNetworkError,
      },
    );
  };

  const handleCodeResend = (phoneNumberToResend: string) => () => {
    getCode.mutate(
      { phoneNumber: parsePhoneNumber(phoneNumberToResend) },
      {
        onSuccess: (data, variables) => {
          setAuthId(data.authId);
          setPhoneNumber(variables.phoneNumber);
        },
        onError: (error) => {
          enqueueSnackbar(getNetworkErrorMessage(error), { variant: "error" });
        },
      },
    );
  };

  const handleBlocked = () => {
    setIsBlocked(true);
  };

  if (authId && phoneNumber) {
    return (
      <React.Fragment>
        <PageTitle subtitle={subtitle} title={title} />
        <Show when={!isBlocked}>
          <ConfirmPhoneNumber
            authId={authId}
            countdown={countdown}
            onBlocked={handleBlocked}
            onResend={handleCodeResend(phoneNumber)}
          />
        </Show>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PageTitle
        subtitle={`${t("login.description")}`}
        title={t("login.title")}
      />
      <EnterPhoneNumber
        error={getCode.error}
        onSubmit={handlePhoneNumberSubmit}
      />
    </React.Fragment>
  );
};
