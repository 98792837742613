import { useTranslation } from "react-i18next";
import { Link, Outlet, useLoaderData, useNavigate } from "react-router-dom";

import { Paths } from "../../shared/config";
import { analytics } from "../../shared/lib/analytics";
import {
  ActionButton,
  Box,
  Container,
  EventButton as Button,
  IconButton,
  IconLocation,
  IconMenu,
  IconQR,
  Stack,
} from "../../shared/ui";
import { mainPageLoader } from "./loader";

export const PageMain = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { defaultTradeOutletId, outlets } = useLoaderData() as Awaited<
    ReturnType<typeof mainPageLoader>
  >;

  const outletData = outlets.find((item) => {
    return item.id === defaultTradeOutletId;
  });

  const handleOutletActionButtonClick = () => {
    analytics.logAction("ClickTradeOutletSelect");
    navigate(Paths.Outlets);
  };

  const handleMenuButtonClick = () => {
    analytics.logAction("ClickMenu");
    navigate(Paths.Profile);
  };

  return (
    <Container>
      <Stack alignItems="center" direction="row" my={2} spacing={1}>
        <Box flex={1}>
          <ActionButton
            onClick={handleOutletActionButtonClick}
            startIcon={<IconLocation color="primary" />}
            text={outletData?.name}
          />
        </Box>
        <IconButton
          onClick={handleMenuButtonClick}
          sx={{
            bgcolor: "common.white",
            borderRadius: "8px",
            height: 56,
            width: 56,
          }}
        >
          <IconMenu />
        </IconButton>
      </Stack>

      <Outlet />

      <Box mt="76px">
        <Box bottom={16} left={16} position="fixed" right={16}>
          <Button
            component={Link}
            fullWidth={true}
            startIcon={<IconQR />}
            to={Paths.NewOrder}
            withEvent={() => analytics.logAction("ClickCreateOrder")}
          >
            {t("orders.create_order_button_text")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
