import { useNavigate } from "react-router-dom";

import {
  Box,
  IconButton,
  IconChevronLeft,
  Stack,
  Typography,
} from "../../../shared/ui";

export type PageTitleProps = {
  subtitle?: React.ReactNode;
  title: string;
};

export const PageTitle = ({ subtitle, title }: PageTitleProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Stack alignItems="flex-start" direction="row" mb={3} ml={-1} spacing={1}>
      <IconButton
        onClick={handleClick}
        sx={{
          color: "neutral.20",
        }}
      >
        <IconChevronLeft />
      </IconButton>
      <Box>
        <Typography
          color="neutral.20"
          lineHeight="40px"
          variant="bigTextMedium"
        >
          {title}
        </Typography>
        <Typography color="neutral.50" variant="regularTextRegular">
          {subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};
