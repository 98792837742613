import "./shared/lib/i18n";

import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "./app";
import { getCountryConfig, getProjectConfig, getTheme } from "./app/configs";
import { Axios } from "./shared/lib/axios/axios";
import { Project } from "./shared/lib/config";

Axios.init();

Project.init({
  country: getCountryConfig("MY"),
  project: getProjectConfig("AHP"),
  theme: getTheme("Aha"),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
);
