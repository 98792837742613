import { Button, ButtonProps } from "..";

// TODO: test and move to uiweb
type Props = ButtonProps & {
  withEvent?: () => void;
};
export const EventButton = ({ withEvent, ...props }: Props) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (withEvent) {
      withEvent();
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <Button {...props} onClick={handleClick}>
      {props.children}
    </Button>
  );
};
