import * as amplitude from "@amplitude/analytics-browser";

import { AMPLITUDE_API_KEY } from "../../../shared/config";
import { ACTION_EVENTS } from "./events";
import { ActionEventKey, ActionEventProperties, UserProperties } from "./types";

class Analytics {
  private _userProperties?: UserProperties;
  private _viewPage = "view_page";

  constructor() {
    if (AMPLITUDE_API_KEY) {
      amplitude.init(AMPLITUDE_API_KEY, {
        defaultTracking: {
          attribution: false,
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
        },
      });
    }
  }

  private _logEvent(name: string, properties?: Record<string, string>) {
    if (!this._userProperties) return;

    amplitude.track(
      name,
      {
        ...properties,
        ...this._userProperties,
      },
      {
        time: Date.now(),
        user_id: this._userProperties.user_id,
      },
    );
  }

  logViewPage(pageName: string) {
    this._logEvent(this._viewPage, { pageName });
  }

  logAction<T extends ActionEventKey>(
    eventKey: T,
    properties?: ActionEventProperties<T>,
  ) {
    const event = ACTION_EVENTS[eventKey];

    // @todo check before runtime
    if ("properties" in event && !properties) {
      throw new Error(`Properties are required for event: ${eventKey}`);
    }

    this._logEvent(event.name, properties);
  }

  setSession(properties?: UserProperties) {
    if (!import.meta.env.PROD || !properties) return;

    this._userProperties = properties;
  }
}

export const analytics = new Analytics();
